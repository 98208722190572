import React from 'react';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import Buttons from './Buttons';
import SurveyPage from './SurveyPage';
import DefaultPage from './DefaultPage';
import LoadingSpinner from './images/loading.gif';
import { useEffect, useState } from 'react';
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size';

const Portal = (props) => {

    const {portalnumber} = props;
    const [fields, setFields] = useState({});
    const [recordID, setRecordID] = useState("");
    const [loading, setLoading] = useState(true);
    const [pageView, setPageView] = useState("");
    const onlyWidth = useWindowWidth();
    const onlyHeight = useWindowHeight();
    const orientation = onlyWidth > onlyHeight ? "landscape" : "portrait";
    const loadingSpinnerStyling = orientation === "landscape" ? {width:"auto", height:"30vh"} : {width:"30vw", height:"auto"};
    
    

    useEffect(() => {
        // var raw = `{"from": "bp3gc9u9s","select": [1,2,3,2510],"where": "{2509.EX.${portalnumber}}",  "sortBy": [    {      "fieldId": 2509,    "order": "ASC"    },    {      "fieldId": 5,      "order": "ASC"    }  ],  "groupBy": [    {      "fieldId": 6,      "grouping": "equal-values"    }  ],  "options": {    "skip": 0,    "top": 5,    "compareWithAppLocalTime": false}}`;
        
        // var requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'QB-Realm-Hostname': 'kota.quickbase.com',
        //         'Authorization': 'QB-USER-TOKEN b5w4ec_nhwb_0_b7qda6sd8zhccbbp2mgx3byak7g9',
        //         'Content-Type': 'application/json; charset=UTF-8'
        //     },
        //     body: raw,
        //     redirect: "follow"
        // };
        
        fetch("https://integrations.sunobiapp.com/customer-portal/design-review", 
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "uniquePortalNumber": parseInt(portalnumber),
                    "jobClist": [ 2509, 2510 ]
                })
            }
        )
        .then(response => response.json())
        .then(data => {
            setFields(JSON.parse(data.data[2510].value));
            setPageView((JSON.parse(data.data[2510].value).portalPageView));
            setLoading(false);
        //setPageView(JSON.parse(res.data[2510].value).fields["portalPageView"]);
            
            //!! setPageView(???);
            //setRecordID(data.data[0][3].value)
            //console.log(JSON.parse(data.data[2510].value));
        })
        
            // setPageView(data.fields.??) === "Awaiting Customer Response" ? "Customer Response" : "Thank You Page";
    
    }, [portalnumber]);
        

    return(
        loading ? <div style={{height:"100vh"}}><div style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}><img style={loadingSpinnerStyling} src={LoadingSpinner}/></div></div> :
        pageView === "NPS" ? 
            <SurveyPage 
                portalnumber={portalnumber}
                scoreRecordID={fields.scoreRecordID}
                installerLogoImageURL={fields.installerLogoImageURL}
                headerHexCode={fields.headerHexCode}
                googleReviewLink={fields.googleReviewLink}
                yelpReviewLink={fields.yelpReviewLink}
            /> :
        pageView === "Default" || pageView === "CRR" || pageView === "Approved" ? 
        <DefaultPage
            installerLogoImageURL={fields.installerLogoImageURL}
            batteryImageURL={fields.batteryImageURL}
            headerHexCode={fields.headerHexCode}
            repName={fields.repName}
            repEmail={fields.repEmail}
            pageView={pageView}
        /> :
        pageView === "Design Review" ? 
        <div style={{
                display:"flex", 
                flexDirection:"column",
                height:"100%"
            }}>
            <Header 
                installerLogoImageURL={fields.installerLogoImageURL}
                headerHexCode={fields.headerHexCode}
            />
            <Body 
                portalnumber={portalnumber} 
                recordID={recordID} 
                setPageView={setPageView}
                designImageURL={fields.designRecord.designImageURL}
                batteryImageURL={fields.batteryImageURL}
                customerName={fields.customerName}
                customerAddress={fields.customerAddress}
                repName={fields.repName}
                repEmail={fields.repEmail}
                loading={loading}
                customerPortalRecordID={fields.designRecord.customerPortalRecordID}
            />
            <Footer
                repName={fields.repName}
                repEmail={fields.repEmail}
            />
        </div>
         :
        <div>Please refresh!</div>
    );
};

export default Portal;