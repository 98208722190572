import React from 'react';

const RatingValue = (props) => {

    const {setRating, rating, value} = props;
    const NPSColor = value < 7 ? "#EA8271" : value < 9 ? "#FDBC5F" : "#75D09B";
    const backgroundColor = rating === value ? NPSColor : null;
    const fontColor = rating === value ? "black" : null;

    return(
        <div className="rating-value-individual-wrapper">
            <div className="rating-value" style={{backgroundColor: backgroundColor, color: fontColor}} onClick={() => setRating(value)}>
                {value}
            </div>
        </div>
    );
};


export default RatingValue;