import React, {useState} from 'react';
import './styles/ThankYouPage.scss';
import Google from './images/googlereview.jpeg';
import Yelp from './images/yelpreview.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ThankYouPage = (props) => {

    const { reviewSubmitted, rating, comment, googleReviewLink, yelpReviewLink } = props;
    const [ copied, setCopied ] = useState(false);
    const copyToClipboardText = copied ? "Copied comments!" : "Copy your comments to clipboard";
    const hasReviewLinks = googleReviewLink ? true : yelpReviewLink ? true : false;
    const hasGoodReview = rating === 9 || rating === 10 ? true : false;
    const askForExtraReview = hasGoodReview && hasReviewLinks? true : false;

    const iPressedCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
    };

    return(
        <div id="thank-you-page-wrapper">
            { hasGoodReview === false ?
                <div id="thank-you-text">
                    Thank you for submitting your score!
                    We appreciate your feedback!
                </div>
                : 
                <div id="positive-thank-you-page-wrapper">
                    <div id="positive-thank-you-text-wrapper">
                        <p>Thanks so much! We're so happy you had a great experience!</p>
                        { askForExtraReview ? <p>If you can take a second to add your review to a site below, we would really appreciate it!</p> : null }
                    </div>
                    { comment && askForExtraReview ? 

                        <CopyToClipboard id="copy-to-clipboard" text={comment} onCopy={() => iPressedCopy()}>
                            <div>
                                <div><button className="button-60">{copyToClipboardText}</button></div>
                            </div>
                        </CopyToClipboard> : 
                        
                        null
                    }
                    <br></br>
                    
                    {hasReviewLinks ?
                    
                        <div id="review-icons">
                            {googleReviewLink ? <div id="google-icon"><a href={googleReviewLink} target="_blank" rel="noopener noreferrer"><img src={Google} alt="Google Review Icon" id="google-icon-image"/></a></div> : null}
                            {yelpReviewLink ? <div id="yelp-icon"><a href={yelpReviewLink} target="_blank" rel="noopener noreferrer"><img src={Yelp} href={yelpReviewLink} alt="Yelp Review Icon" id="yelp-icon-image"/></a></div> : null}
                        </div>

                        : null
                    }
                </div> 
            }
        </div>
    );
};

export default ThankYouPage;