import React from 'react';
import Buttons from './Buttons';
import './styles/Body.scss';
import { SRLWrapper } from "simple-react-lightbox";

const options = {
    settings: {
        overlayColor: "rgba(0,0,0,0.75);",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
        disablePanzoom: true,
        disableKeyboardControls: false,
        disableWheelControls: true,
        lightboxTransitionSpeed: 0.1
    },
    buttons: {
        backgroundColor: "#404040",
        iconColor: "white",
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: false,
        showPrevButton: false,
        showThumbnailsButton: true,
    },
    caption: {
        showCaption: false
    },
    thumbnails: {
        showThumbnails: false
    }
}

const Body = (props) => {

    const { portalnumber, recordID, loading, setPageView, designImageURL, batteryImageURL, customerName, customerAddress, repName, repEmail, customerPortalRecordID } = props;

    return (
        <div id="outer-body-wrapper">
            <div id="body-wrapper">
                <div id="page-description">
                    <br />
                    Hello {customerName}<br />
                    <h3 style={{ fontSize: "1.5em", fontWeight: "bold", lineHeight: "1.4em" }}>Please review your design!</h3>
                    <div id="main-paragraph">
                        If everything looks good, simply press the 'Approve' button and we will get your project over to our permitting team ASAP.
                        <div className="break"></div>
                        <span style={{ color: "#C11F2D" }}>If we do not hear from you within 72 hours, we will assume this design has met your expectations and will submit to our permitting team so that we can keep your project moving.</span>
                        <div className="break"></div>
                        If you have any questions at all, please contact your sales rep,<br></br> <span style={{ fontWeight: "bold" }}>{repName}</span> at <span style={{ fontWeight: "bold" }}>{repEmail}</span><br />
                    </div>
                </div>
                <div id="outer-outer-wrapper">
                    <div style={{ height: "100%", padding: "0px 0px", alignSelf: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div id="customer-address">
                                {customerAddress}
                            </div>
                            <SRLWrapper options={options}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div id="image-wrapper">
                                        <img alt="solar-design-image" id="design-image" src={designImageURL} />
                                        <div id="click-text">Click solar design image to enlarge</div>
                                    </div>
                                </div>
                            </SRLWrapper>
                            {
                                batteryImageURL > "" ?
                                    <SRLWrapper options={options}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div id="image-wrapper">
                                                <img alt="battery-design-image" id="battery-image" src={batteryImageURL} />
                                                <div id="click-text">Click battery image to enlarge</div>
                                            </div>
                                        </div>
                                    </SRLWrapper>
                                    : null
                            }
                        </div>
                        <Buttons
                            loading={loading}
                            portalnumber={portalnumber}
                            setPageView={setPageView}
                            customerPortalRecordID={customerPortalRecordID}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Body;