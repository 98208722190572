import React, { useState } from 'react';
import './styles/Buttons.scss';

const SubmitButton = (props) => {

    const { setPageView, portalnumber, comment, rating, setReviewSubmitted, scoreRecordID } = props;
    const buttonClass = rating < 7 ? "approveButton-red" : rating < 9 ? "approveButton-orange" : "approveButton";
    // const buttonStyling = rating ? {backgroundColor:`${backgroundColor}`, borderColor:`${backgroundColor}`, transition: "0.2s"} : null;
    const buttonStyling = rating ? {transition: "0.2s"} : null;
    // Try dynamically changing the class name instead???

    // console.log(portalnumber);
    // console.log(scoreRecordID);

    const buttonDisabled = rating === 0 || rating > 0 ? false : true;
    const [sendingSubmitted, setSendingSubmitted] = useState(false); // Whether the API request is currently sending
    const [submitButtonContent, setSubmitButtonContent] = useState("Send Your Score"); //Content shown on the submit button
    const [displayError, setDisplayError] = useState("none"); // Shows/hides error message if API call fails
    const [imageLoaded, setImageLoaded] = useState(false); // Used to toggle loading skeleton if selected image hasn't loaded
    const skeletonDisplay = imageLoaded ? "none" : "block"; // Loading skeleton shown while image is loading
    const imageDisplay = imageLoaded ? "block" : "none"; // Replaces loading skeleton with image once loaded

    const onClickHandler = () => {
        // setTimeout(() => console.log('Initial timeout!'), 1000);
        submitAPI();
        setTimeout(() => setReviewSubmitted(true, 1000));
    };

    const submitAPI = (props) => {
        
        setSendingSubmitted(true);
        setSubmitButtonContent(<img alt="Loading spinner" src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/>);

        var raw = `{"recordID": ${scoreRecordID},"uniquePortalNumber": ${portalnumber},"rating": ${rating},"comment": "${comment.replace(/(\r\n|\n|\r)/gm, "")}"}`;

        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: raw,
            redirect: "follow"
        };
        
        fetch("https://integrations.sunobiapp.com/customer-portal/design-review/survey-score", requestOptions)
            .then(response => response.json() && console.log(response))
            //.then(() => setSendingSubmitted(false)) 
            //.then(() => setSubmitButtonContent("Submitted!"))
            .then(() => console.log('Record successfully updated!'))
            //.then(() => setPageView("Submitted"))
            .catch((error) => {setSubmitButtonContent("Error"); setDisplayError("block"); console.log(error)});
    };

    return(
        <button 
            disabled={buttonDisabled} 
            className={buttonClass}
            //onClick={() => submitAPI()}
            //onClick={() => setReviewSubmitted(true)}
            onClick={() => onClickHandler()}
        >
            {submitButtonContent}
        </button>
    );
};


export default SubmitButton;