import React from 'react';
import ApproveButton from './ApproveButton';
import RequestButton from './RequestButton';

const Buttons = (props) => {

    const { loading, setPageView, customerPortalRecordID, portalnumber } = props;

    return(
        loading ? 

        //! Loading State !//

            <div id="buttons-skeleton">
                {/* <Skeleton height={50}/> */}
            </div> :

        //? Loaded State ?//

            <div id="button-wrapper-1">
                <div id="button-wrapper-2">
                    <ApproveButton 
                        setPageView={setPageView}
                        customerPortalRecordID={customerPortalRecordID}
                        portalnumber={portalnumber}
                    />
                    <RequestButton 
                        setPageView={setPageView}
                        customerPortalRecordID={customerPortalRecordID}
                        portalnumber={portalnumber}
                    />
                </div>
            </div>
    );
};

export default Buttons;