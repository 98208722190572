import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import './styles/Buttons.scss';
import './styles/Popups.scss';

const ApproveButton = (props) => {

    const { setPageView, portalnumber, customerPortalRecordID } = props;

    const [request, setRequest] = useState("");
    const [sendingRequest, setSendingRequest] = useState(false);
    const [revButtonContent, setRevButtonContent] = useState("Submit");
    const revisionButtonContent = sendingRequest ? <img src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/> : "Submit";
    const buttonDisabled = request.length===0 || revButtonContent !== "Submit" ?  true : false;
    const [ displayError, setDisplayError ] = useState("none");
    const buttonAlignment = displayError === "none" ? "flex-end" : "space-between";

    const revisionRequestHandler = () => {

        setSendingRequest(true);
        setRevButtonContent(<img alt="Loading spinner" src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/>);

        var raw = `{"uniquePortalNumber": ${portalnumber},"responseRecordID": ${customerPortalRecordID},"response": "CRR", "requestedChanges": "${request}"}`;
        
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: raw,
            redirect: "follow"
        };
        
        fetch("https://integrations.sunobiapp.com/customer-portal/design-review/submit-review", requestOptions)
            .then(response => response.json())
            .then(() => setSendingRequest(false))
            .then(() => setRevButtonContent("Submitted!"))
            .then(() => setPageView("CRR"))
            .catch((error) => {
                setRevButtonContent("Submit")
                setDisplayError("block")
            });
        };

    return(
        <Popup trigger={<button className="request-revision-button">Request Changes</button>} modal>
        {/*Change button class to requestButton to revert*/}
            {close => (
                <div className="content">
                    <div style={{paddingBottom:"10px"}} id="close-wrapper">
                        <div id="popup-header">
                            Request Changes
                        </div>
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </div>
                    <div id="request-box-wrapper">
                        <p id="revision-modal-content">Describe the changes you want below</p>
                        <textarea
                            name="requested-changes"
                            id="request-changes-box"
                            type="text"
                            value={request}
                            onChange={e => setRequest(e.target.value)}
                            >
                        </textarea>
                    </div>
                    <div id="checkbox-wrapper" style={{display: "flex", justifyContent:`${buttonAlignment}`, padding:"10px"}}>
                        <div style={{width:"max-content", paddingLeft:"5px", paddingRight:"10px", color: "red", display:`${displayError}`}}>
                            Error: Please email us if you are not redirected to the 'Thank you' page.
                        </div>          
                        <div className="popup-approve-button">
                            <button disabled={buttonDisabled} className="approveButton" style={{width:"110px"}} onClick={() => revisionRequestHandler()}>
                                {revButtonContent}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};


export default ApproveButton;