import React from 'react';
import { ReactComponent as KotaLogo } from './images/kotalogo.svg';
import './styles/Header.scss';

const Header = (props) => {

    const { installerLogoImageURL, headerHexCode } = props;
    const headerBackgroundColor = headerHexCode ? headerHexCode : "FFFFFF"

    return(
        <div id="header-wrapper" style={{backgroundColor:`#${headerBackgroundColor}`}}>
            <div id="header-logo-wrapper">
                    <img alt="solar-company-logo" id="installer-logo" src={installerLogoImageURL}/>
                    {/* <KotaLogo id="kota-logo"/> */}
            </div>
        </div>
    );
};

export default Header;