import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './styles/DefaultPage.scss';

const DefaultPage = (props) => {

    const { repName, repEmail, installerLogoImageURL, headerHexCode, pageView } = props;

    return(
        <div id="default-page-wrapper">
            <Header 
                installerLogoImageURL={installerLogoImageURL}
                headerHexCode={headerHexCode}
            />
            <div id="default-page-text-wrapper">
                { pageView === "CRR" ?
                    <div id="thank-you-page-wrapper">
                        <div id="thank-you-text">
                            Thank you, we are now reviewing your requested changes!
                            <br></br><br></br>
                            We will let you know when your new design is ready to review.
                        </div>
                    </div>
                : pageView === "Approved" ?
                    <div id="thank-you-page-wrapper">
                        <div id="thank-you-text">
                            Thank you for approving your design - you're one step closer to solar! 
                            <br></br><br></br>
                            Our permitting team will take it from here!
                        </div>
                    </div>
                : 
                    <div>
                        <div>There's nothing else you need to do on this page right now!</div>
                        <br></br>
                        <div>For any questions please contact your rep, {repName}, at <a id="rep-email" href={`mailto:${repEmail}`}>{repEmail}</a></div>
                    </div>
                }
            </div>
            <Footer repName={repName}
                repEmail={repEmail}/>
        </div>
    );
};

export default DefaultPage;