import './App.scss';
import Portal from './components/Portal';
import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  //Link,
  useParams
} from 'react-router-dom';

export default function App() {

  return (
    <div id="app-wrapper">
      <Router>
        <Routes>
          <Route path="/:portalnumber" element={<Child/>}/>
        </Routes>
      </Router>
    </div>
  );
};

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let portalnumber = useParams();
 // console.log(useParams());

  return (
    <div style={{height:"100%"}}>
      <Portal portalnumber={portalnumber.portalnumber}/>
    </div>
  );
};