import React from 'react';
import './styles/Footer.scss';

const Footer = (props) => {

    const { repName, repEmail } = props;
    const currentYear = new Date().getFullYear();

    return(
        <div id="footer-wrapper">
            <div id="footer-content-wrapper">
                    <div id="kota-text">KOTA Energy Group © {currentYear}</div>
                    <div id="questions-text">Any questions?<br/><a style={{color: "white"}} href={`mailto:${repEmail}`}>{repEmail}</a></div>
            </div>
        </div>
    );
};

export default Footer;