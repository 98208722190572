import React, { useState } from 'react';
import './styles/Buttons.scss';

const ApproveButton = (props) => {

    const { setPageView, portalnumber, customerPortalRecordID } = props;

    const [ sendingApproved, setSendingApproved ] = useState(false); // Whether the API request is currently sending
    const [ approveButtonContent, setApproveButtonContent ] = useState("Approve Design"); //Content shown on the approve button
    const [ displayError, setDisplayError ] = useState("none"); // Shows/hides error message if API call fails
    const [ imageLoaded, setImageLoaded ] = useState(false); // Used to toggle loading skeleton if selected image hasn't loaded
    const skeletonDisplay = imageLoaded ? "none" : "block"; // Loading skeleton shown while image is loading
    const imageDisplay = imageLoaded ? "block" : "none"; // Replaces loading skeleton with image once loaded

    const approveAPI = () => {
        setSendingApproved(true);
        setApproveButtonContent(<img alt="Loading spinner" src="https://i.imgur.com/tEToq5L.gif" style={{width:"30px", height:"30px"}}/>);

        var raw = `{"uniquePortalNumber": ${portalnumber},"responseRecordID": ${customerPortalRecordID},"response": "Approved"}`;
        
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: raw,
            redirect: "follow"
        };
        
        fetch("https://integrations.sunobiapp.com/customer-portal/design-review/submit-review", requestOptions)
            .then(response => response.json())
            .then(() => setSendingApproved(false)) 
            .then(() => setApproveButtonContent("Approved!"))
           // .then(() => console.log('Record successfully updated!'))
            .then(() => setPageView("Approved"))
            .catch((error) => {setApproveButtonContent("Error"); setDisplayError("block"); console.log(error)});
};

    return(
        <button 
            //disabled={buttonDisabled} 
            className="approveButton" 
            onClick={() => approveAPI()}
        >
            {approveButtonContent}
        </button>
    );
};


export default ApproveButton;