import React from 'react';
import { useState, useEffect } from 'react';
import { ReactComponent as KotaLogo } from './images/kotalogo.svg';
import Header from './Header';
import RatingValue from './RatingValue';
import './styles/Survey.scss';
import SubmitButton from './SubmitButton';
import ThankYouPage from './ThankYouPage';

import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
  } from '@react-hook/window-size';

const SurveyPage = (props) => {


    const params = new URLSearchParams(window.location.search);
    const emailRating = parseInt(params.get('rating'));
    const { portalnumber, scoreRecordID, installerLogoImageURL, headerHexCode, googleReviewLink, yelpReviewLink } = props;
    const [ rating, setRating ] = useState(emailRating);
    const [ comment, setComment ] = useState("");
    const normalisedComment = comment.replace(/[^\w\s]/gi, '');
    console.log(comment);
    console.log(normalisedComment);
    const [ reviewSubmitted, setReviewSubmitted ] = useState(false);
    const onlyWidth = useWindowWidth();
    const reshapeValues = onlyWidth < 640 ? true : false;
    const reshapeValuesSmaller = onlyWidth < 360 ? true : false;

    return(
        <div>
            <Header headerHexCode={headerHexCode} installerLogoImageURL={installerLogoImageURL}/>
            { reviewSubmitted ? 
                <ThankYouPage 
                    reviewSubmitted={reviewSubmitted} 
                    rating={rating} 
                    comment={comment} 
                    scoreRecordID={scoreRecordID}
                    googleReviewLink={googleReviewLink}
                    yelpReviewLink={yelpReviewLink}
                /> :
                <div id="rating-content-wrapper">
                    <p id="rating-question">How likely are you to recommend KOTA Energy Group to a friend or colleague?<span style={{color:"red", fontSize:"1.25em"}}> *</span></p>
                    <div id="rating-value-wrapper">
                        
                        {reshapeValues ? 

                            // Wrap numbers on small screen size
                            
                            <div>
                                <div id="rating-numbers-sections-wrapper" style={{justifyContent:"center"}}>
                                    <RatingValue setRating={setRating} rating={rating} value={0}/>
                                    <RatingValue setRating={setRating} rating={rating} value={1}/>
                                    <RatingValue setRating={setRating} rating={rating} value={2}/>
                                    <RatingValue setRating={setRating} rating={rating} value={3}/>
                                    <RatingValue setRating={setRating} rating={rating} value={4}/>
                                </div>
                                <div id="rating-numbers-sections-wrapper">
                                    <RatingValue setRating={setRating} rating={rating} value={5}/>
                                    <RatingValue setRating={setRating} rating={rating} value={6}/>
                                    <RatingValue setRating={setRating} rating={rating} value={7}/>
                                    <RatingValue setRating={setRating} rating={rating} value={8}/>
                                    <RatingValue setRating={setRating} rating={rating} value={9}/>
                                    { reshapeValuesSmaller ?  null : 
                                        <RatingValue setRating={setRating} rating={rating} value={10}/>
                                    }
                                </div>
                                    { reshapeValuesSmaller ? 
                                        <div id="rating-numbers-sections-wrapper" style={{justifyContent:"center"}}>
                                            <RatingValue setRating={setRating} rating={rating} value={10}/>
                                        </div> : null
                                    }
                            </div> :

                            
                            //Inline numbers on big screen size

                            <div id="rating-numbers-sections-wrapper">
                                <RatingValue setRating={setRating} rating={rating} value={0}/>
                                <RatingValue setRating={setRating} rating={rating} value={1}/>
                                <RatingValue setRating={setRating} rating={rating} value={2}/>
                                <RatingValue setRating={setRating} rating={rating} value={3}/>
                                <RatingValue setRating={setRating} rating={rating} value={4}/>
                                <RatingValue setRating={setRating} rating={rating} value={5}/>
                                <RatingValue setRating={setRating} rating={rating} value={6}/>
                                <RatingValue setRating={setRating} rating={rating} value={7}/>
                                <RatingValue setRating={setRating} rating={rating} value={8}/>
                                <RatingValue setRating={setRating} rating={rating} value={9}/>
                                <RatingValue setRating={setRating} rating={rating} value={10}/>
                            </div>
                    }
                    { reshapeValues ? 
                        null : 
                        <div id="ratings-text-wrapper">
                            <p>Very unlikely</p>
                            <p>Very likely</p>
                        </div>
                    }
                </div>
                <textarea id="comment-area" placeholder="Optional comments" type="text" value={comment}
                                onChange={e => setComment(e.target.value)}></textarea>
                <div id="submit-button-wrapper">
                    <SubmitButton 
                        comment={comment} 
                        rating={rating} 
                        setReviewSubmitted={setReviewSubmitted}
                        portalnumber={portalnumber}
                        scoreRecordID={scoreRecordID}
                    />
                </div>
            </div>
            }
        </div>
    );
};


export default SurveyPage;